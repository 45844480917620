<template>
<div>
    <el-menu
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#FFFFFF"
      text-color="#030303"
      active-text-color="#303030"
      :router="true">
      <el-submenu index="1" v-if="level == '1' | level == '2'">
        <template slot="title">
          <i class="el-icon-s-home"></i>
          <span style="font-size:15px;color = #ffffff" >单位管理</span>
        </template>
             <el-menu-item-group >
              <el-menu-item index='/Robot_Management/units'  style="font-size:13px" >单位管理</el-menu-item>
              <el-menu-item index='/Robot_Management/unitsapply'  style="font-size:13px" >单位绑定申请</el-menu-item>
              <el-menu-item index='/Robot_Management/unitsapproval'  style="font-size:13px" >单位绑定审核</el-menu-item>
            </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span style="font-size:15px">用户管理</span>
        </template>
            <el-menu-item-group >
              <el-menu-item index='/Robot_Management/users'  style="font-size:13px" >用户管理</el-menu-item>
              <el-menu-item index='/Robot_Management/usersapply' style="font-size:13px" >用户绑定申请</el-menu-item>
              <el-menu-item index='/Robot_Management/usersapproval' style="font-size:13px" v-if="level == '1' | level == '2'">用户绑定审核</el-menu-item>
              <el-menu-item index='/Robot_Management/loginlog'  style="font-size:13px" >登录日志</el-menu-item>
            </el-menu-item-group>
      </el-submenu>
    <el-submenu index="3">
      <template slot="title">
        <i class="el-icon-menu-robot"></i>
        <span style="font-size:15px;align=center">机器人管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index='/Robot_Management/robot'  style="font-size:13px" v-if="level == '1'">机器人管理</el-menu-item>
        <el-menu-item index='/Robot_Management/query_robot'  style="font-size:13px" v-if="level == '1'">机器人类别</el-menu-item>
        <el-menu-item index='/Robot_Management/my_robot'  style="font-size:13px">机器人运行</el-menu-item>
        <el-menu-item index='/Robot_Management/case'  style="font-size:13px">案例展示</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-edit"></i>
        <span style="font-size:15px;align=center">设计器管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index='/Robot_Management/designer'  style="font-size:13px" >下载设计器</el-menu-item>
        <el-menu-item index='/Robot_Management/designerapply'  style="font-size:13px" >授权申请</el-menu-item>
        <el-menu-item index='/Robot_Management/designerapproval'  style="font-size:13px" v-if="level == '1' | level == '2'">授权审核</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item index="/Robot_Management/supervisory" style="font-size:15px">
      <i class="el-icon-monitor" style="color: #fa0707;"></i>
      <span slot="title">大屏监控</span>
    </el-menu-item>
    <el-menu-item index="/Robot_Management/ai" style="font-size:15px" v-if="level == '1'">
      <i class="el-icon-chat-line-round" style="color: #fa0707;"></i>
      <span slot="title">AI大模型</span>
    </el-menu-item>
    </el-menu>
</div>
</template>

<script>
export default {
  data () {
    return {
      robot_list: [],
      level: ''
    }
  },
  created () {
    this.level = localStorage.getItem('role_id')
    // console.log('当前登录名：', localStorage.getItem('yun_user'))
    // this.axios.get('/rightlist/', {
    // }).then(res => {
    //   console.log('当前返回菜单权限', res.data)
    //   this.robot_list = res.data
    // })
    // this.axios.post('', {})
  },
  mounted () {
    console.log(this.$route.name)
    this.active = this.$route.name
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style scoped>
span{
  margin-top:-10px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 199px;
  /* min-height: 1000px; */
  text-align: left
}

.el-aside {
display: block;
position: relative;
overflow-y: scroll;
background-color: #eee !important;
width: 260px !important;
}
.el-submenu__title{
    font-size: 20px;
    font-weight: bold;
    color: #0c0c0c;
    border-bottom: 2px solid #0a0a0a;
}
.el-submenu__title i{
  color: #fa0707;
}
.el-submenu__title span{
  color: #0a0a0a;
}
.el-submenu [class^=el-icon-]{
  font-size: 20px;
}
.el-menu-item, .el-submenu__title{
  height: 66px;
  line-height: 66px;
  margin-top:-10px;
}
.el-submenu__icon-arrow {
    position: relative;
    top: 0%;
    right: 0;
    margin-top: 0;
    font-size: 20px;
    margin-left: 8px;
}
.el-menu-item-group__title{
  padding: 0;
}
.el-submenu .el-menu-item{
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: rgb(19, 3, 3);
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    color: rgb(7, 136, 50) !important;
    background-color: rgb(148, 213, 215);
}
.el-submenu .el-menu {
    border: none;
    border-bottom: 1px solid #C76362;
}
.el-submenu__title:hover {
    background-color: #C76362;
}
.el-menu-item-group>ul li .el-submenu__title{
  font-size: 18px;
  font-weight: normal;
  border-bottom: 1px solid #C76362;
}
.el-menu-item-group>ul li .el-submenu__title {
    font-size: 18px;
    font-weight: normal;
    border-bottom: 1px solid #C76362;
    height: 50px;
    line-height: 50px;
}
.el-icon-locationee{
      background: url('../../assets/images/left_icon1.png') center center no-repeat;
    background-size: 20px;
}
.el-icon-locationee:before {
    content: "替";
    font-size: 16px;
    visibility: hidden;
}
.el-icon-menu{
      background: url('../../assets/images/left_icon2.png') center center no-repeat;
    background-size: 20px;
}
.el-icon-menu:before {
    content: "替";
    font-size: 16px;
    visibility: hidden;
}
.el-icon-menu-robot{
      background: url('../../assets/images/left_icon3.png') center center no-repeat;
    background-size: 20px;
}
.el-icon-menu-robot:before {
    content: "替";
    font-size: 16px;
    visibility: hidden;
}
.el-scrollbar__wrap {
   overflow-x: hidden;
}
</style>
